import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Segment, Card, Icon, Input, TextArea, Dropdown } from 'semantic-ui-react';
import { getDatabase, ref, push, set, onValue } from 'firebase/database';
import { getStorage, ref as storageRef, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import '../App.css';
import TaskCard from './TaskCard';
import { categoryOptions, statusOptions } from './TaskOptions';

const Tasks = () => {
  const [taskName, setTaskName] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [status, setStatus] = useState('');
  const [file, setFile] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [showAddTaskForm, setShowAddTaskForm] = useState(false);
  const [description, setDescription] = useState('');
  const [taskCategory, setTaskCategory] = useState('');
  const database = getDatabase();
  const storage = getStorage();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const usersRef = ref(database, 'users');
    const unsubscribe = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      const userList = data ? Object.keys(data).map((key) => ({
        key: key,
        text: data[key].name || data[key].email,
        value: key,
      })) : [];
      setUsers(userList);
    });
    return () => unsubscribe();
  }, [database]);

  const fetchFilesForTask = useCallback((taskId) => {
    const filesRef = storageRef(storage, `Tasks/${taskId}`);
    return listAll(filesRef)
      .then((result) => Promise.all(result.items.map((itemRef) => getDownloadURL(itemRef))))
      .catch((error) => {
        console.error("Error fetching files: ", error);
        return [];
      });
  }, [storage]);

  const fetchTasks = useCallback(() => {
    const tasksRef = ref(database, 'tasks');
    onValue(tasksRef, async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const tasksWithFiles = await Promise.all(Object.entries(data).map(async ([key, value]) => {
          const fileUrls = await fetchFilesForTask(key);
          return { id: key, ...value, fileUrls };
        }));
        setTasks(tasksWithFiles);
      } else {
        setTasks([]);
      }
    });
  }, [database, fetchFilesForTask]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const handleAddTask = (event) => {
    event.preventDefault();
    const newTaskRef = push(ref(database, 'tasks'));
    const taskData = {
      name: taskName,
      assignedTo: selectedUser,
      dueDate,
      status,
      description,
      category: taskCategory,
    };
    set(newTaskRef, taskData)
      .then(() => {
        if (file) {
          const fileRef = storageRef(storage, `Tasks/${newTaskRef.key}/${file.name}`);
          const metadata = {
            customMetadata: {
              'taskId': newTaskRef.key,
            },
          };
          uploadBytes(fileRef, file, metadata)
            .then(() => console.log('Uploaded a file for task!'))
            .catch((error) => console.error('Error uploading file:', error));
        }
        setShowAddTaskForm(false);
        setTaskName('');
        setAssignedTo('');
        setDueDate('');
        setStatus('');
        setFile(null);
        setDescription('');
        setTaskCategory('');
      })
      .catch((error) => console.error('Error adding task:', error));
  };

  const [fileName, setFileName] = useState('Choose file');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFile(file);
    } else {
      setFileName('Choose file');
    }
  };

  const handleCancel = () => {
    setTaskName('');
    setAssignedTo('');
    setDueDate('');
    setStatus('');
    setDescription('');
    setTaskCategory('');
    setFile(null);
    setShowAddTaskForm(false);
  };

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useEffect(() => {
    const allFieldsFilled = taskName && assignedTo && dueDate && status && description && taskCategory;
    setIsSubmitEnabled(allFieldsFilled);
  }, [taskName, assignedTo, dueDate, status, description, taskCategory]);

  const updateTaskStatus = (taskId, newStatus) => {
    const taskStatusRef = ref(database, `tasks/${taskId}/status`);
    set(taskStatusRef, newStatus).then(() => console.log(`Status updated to ${newStatus}`)).catch((error) => console.error('Error updating status:', error));
  };

  const updateTaskCategory = (taskId, newCategory) => {
    const taskCategoryRef = ref(database, `tasks/${taskId}/category`);
    set(taskCategoryRef, newCategory).then(() => console.log(`Category updated to ${newCategory}`)).catch((error) => console.error('Error updating category:', error));
  };

  const sortedTasks = tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

  const cardGroupStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
    gridGap: '20px',
    justifyContent: 'center',
  };

  return (
    <Segment>
      <div style={{ marginBottom: '1em' }}>
        <Button primary onClick={() => setShowAddTaskForm(!showAddTaskForm)} className="add-task-button">
          <Icon name="plus" /> Add a Task
        </Button>
      </div>
      {showAddTaskForm && (
        <Form onSubmit={handleAddTask} className="add-task-form">
          <Form.Field>
            <label>Task Name</label>
            <Input
              fluid
              placeholder='Enter task name'
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <TextArea
              placeholder='Enter task description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Assign To</label>
            <Dropdown
              placeholder='Assign To'
              fluid
              selection
              search
              options={users}
              value={assignedTo}
              onChange={(e, { value }) => {
                setAssignedTo(value);
                setSelectedUser(value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Due Date</label>
            <Input
              fluid
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </Form.Field>
          <Form.Select
            fluid
            label="Select Status"
            options={statusOptions}
            placeholder="Select Status"
            value={status}
            onChange={(e, { value }) => setStatus(value)}
          />
          <Form.Select
            fluid
            label="Category"
            options={categoryOptions}
            placeholder="Select Category"
            value={taskCategory}
            onChange={(e, { value }) => setTaskCategory(value)}
          />
          <Form.Field>
            <label>File</label>
            <Button as="label" htmlFor="file" type="button" fluid>
              <Icon name="upload" />
              {fileName}
            </Button>
            <input
              type="file"
              id="file"
              hidden
              onChange={handleFileChange}
            />
          </Form.Field>
          <Button.Group fluid>
            <Button primary type="submit" disabled={!isSubmitEnabled}>
              <Icon name="checkmark" /> Submit Task
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Button.Group>
        </Form>
      )}
      {!showAddTaskForm && (
        <Card.Group itemsPerRow={4} style={cardGroupStyle}>
          {sortedTasks.length > 0 ? (
            sortedTasks.map((task) => (
              <TaskCard
                key={task.id}
                task={{
                  ...task,
                  assignedTo: task.assignedTo,
                }}
                onStatusChange={updateTaskStatus}
                onCategoryChange={updateTaskCategory}
              />
            ))
          ) : (
            <div>No tasks yet.</div>
          )}
        </Card.Group>
      )}
    </Segment>
  );
};

export default Tasks;
