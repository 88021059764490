import React from 'react';
import { LoadScript } from '@react-google-maps/api';

const WithGoogleScript = (WrappedComponent) => {
  return (props) => (
    <LoadScript googleMapsApiKey="AIzaSyBgMRc2kHSqryUDKeFZQRNGr91MAbgmnuw">
      <WrappedComponent {...props} />
    </LoadScript>
  );
};

export default WithGoogleScript;
