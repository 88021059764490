// ForgotPassword.js
import React, { useState } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { sendPasswordResetEmail } from '../Firebase';

const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(email); // Use the function directly
      setMessage('Check your email to reset your password.');
    } catch (error) {
      setError(error.message);
    }
  }; 

  return (
    <div>
      {error && <Message error content={error} />}
      {message && <Message success content={message} />}
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Input
            icon='mail'
            iconPosition='left'
            placeholder='Enter your email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Button type='submit' fluid primary>Send Reset Email</Button>
      </Form>
      <Button onClick={onClose} color='red' fluid style={{ marginTop: '20px' }}>Cancel</Button>
    </div>
  );
};

export default ForgotPassword;
