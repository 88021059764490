import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Dropdown, /* Input, Segment, */ Icon } from 'semantic-ui-react';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, getMetadata } from 'firebase/storage';
import { auth } from './Firebase'; // Make sure this path is correct

const FileManagement = () => {
  const [uploadFile, setUploadFile] = useState(null);
  const [fileTag, setFileTag] = useState(''); // State to hold the input tag
  const [files, setFiles] = useState([]);
  const storage = getStorage();
  const [activeTagFilter, setActiveTagFilter] = useState('All Tags');

  // Predefined set of tags for easy file classification
  const tagOptions = [
    { key: 'project_plan', text: 'Project Plan', value: 'Project Plan' },
    { key: 'meeting_minutes', text: 'Meeting Minutes', value: 'Meeting Minutes' },
    { key: 'financial_report', text: 'Financial Report', value: 'Financial Report' },
    { key: 'presentation', text: 'Presentation', value: 'Presentation' },
    { key: 'contract', text: 'Contract', value: 'Contract' },
    { key: 'proposal', text: 'Proposal', value: 'Proposal' },
    { key: 'technical_documentation', text: 'Technical Documentation', value: 'Technical Documentation' },
    { key: 'design_assets', text: 'Design Assets', value: 'Design Assets' },
    { key: 'research', text: 'Research', value: 'Research' },
    { key: 'legal_document', text: 'Legal Document', value: 'Legal Document' },
    { key: 'correspondence', text: 'Correspondence', value: 'Correspondence' },
    { key: 'marketing_material', text: 'Marketing Material', value: 'Marketing Material' },
    // ...add more tags as needed for your specific project management needs
  ];

  const fetchFiles = useCallback(() => {
    const filesRef = ref(storage, 'Files');
    listAll(filesRef)
    .then((result) => {
      return Promise.all(result.items.map((itemRef) => {
        return getDownloadURL(itemRef).then((url) => {
          return getMetadata(itemRef).then((metadata) => {
            return {
              name: itemRef.name,
              url: url,
              timeCreated: metadata.timeCreated,
              uploader: metadata.customMetadata?.uploader || 'Unknown',
              tag: metadata.customMetadata?.tag || 'No Tag', // Get the tag from metadata
            };
          });
        });
      }));
    })
      .then((filesData) => {
        setFiles(filesData);
      })
      .catch((error) => {
        console.error("Error fetching files: ", error);
      });
  }, [storage]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const filteredFiles = activeTagFilter === 'All Tags' 
    ? files 
    : files.filter(file => file.tag === activeTagFilter);

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setUploadFile(file);
      } else {
        setUploadFile(null);
      }
    }; 

  // Use a function to handle tag selection and filter files
  const handleTagFilterChange = (e, { value }) => {
    setActiveTagFilter(value);
  };

  const handleUpload = () => {
    if (uploadFile) {
      const storageRef = ref(storage, `Files/${uploadFile.name}`);
      const uploadMetadata = {
        customMetadata: {
          'uploader': auth.currentUser.email,
          'tag': fileTag, // Save the tag as part of the metadata
        }
      };
      uploadBytes(storageRef, uploadFile, uploadMetadata)
        .then((snapshot) => {
          console.log('Uploaded a blob or file!');
          setUploadFile(null); // Reset the file input after successful upload
          setFileTag(''); // Clear the tag state
          fetchFiles(); // Refresh the file list after upload
        })
        .catch((error) => {
          console.error("Error uploading file: ", error);
        });
    }
  };

  // You can create a label text that either shows 'Choose file' or the selected file name
  const fileInputLabel = uploadFile ? uploadFile.name : 'Choose file';

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
        <Button as="label" htmlFor="fileInput" primary>
          <Icon name="upload" />
          {fileInputLabel}
        </Button>
        <input
          key={uploadFile ? uploadFile.name : 'no-file'}
          id="fileInput"
          type="file"
          hidden
          onChange={handleFileChange}
        />
        {uploadFile && (
          <>
            <Dropdown
              placeholder="Select file tag"
              selection
              options={tagOptions}
              value={fileTag}
              onChange={(e, { value }) => setFileTag(value)}
            />
            {/* The Upload File button is now only active if a tag is selected and a file is chosen */}
            <Button 
              onClick={handleUpload} 
              color='red' 
              disabled={!fileTag || !uploadFile} // Disabled if no file tag is selected or no file is chosen
            >
              Upload File
            </Button>
          </>
        )}
      </div>
  
      <Dropdown
        placeholder='Filter by Tag'
        selection
        options={[{ key: 'all', text: 'All Tags', value: 'All Tags' }, ...tagOptions]}
        onChange={handleTagFilterChange}
        style={{ marginBottom: '20px' }}
      />
  
      <Card.Group itemsPerRow={3}>
        {filteredFiles.length > 0 ? (
          filteredFiles.map((file, index) => (
            <Card key={index}>
              <Card.Content>
                <Card.Header>
                  <a href={file.url} target="_blank" rel="noopener noreferrer" download={file.name}>
                    {file.name}
                  </a>
                </Card.Header>
                <Card.Meta>
                  Uploaded on {new Date(file.timeCreated).toLocaleDateString()}
                </Card.Meta>
                <Card.Description>
                  Uploaded by {file.uploader}
                </Card.Description>
                <Card.Description>
                  Tag: {file.tag}
                </Card.Description>
              </Card.Content>
            </Card>
          ))
        ) : (
          <div style={{ textAlign: 'center', width: '100%' }}>No files of that category</div>
        )}
      </Card.Group>
    </div>
  );

};

export default FileManagement;