import React, { useState, useEffect } from 'react';
import { Card, Icon, Dropdown, Button, Input, TextArea } from 'semantic-ui-react';
import { categoryOptions } from './TaskOptions';
import { getDatabase, ref, get, set } from 'firebase/database';

const TaskCard = ({ task, onStatusChange, onCategoryChange }) => {
  const [editing, setEditing] = useState(false);
  const [selected] = useState(false);
  const [editedStatus, setEditedStatus] = useState(task.status);
  const [editedCategory, setEditedCategory] = useState(task.category);
  const [assignedUserName, setAssignedUserName] = useState('');
  const [editedName, setEditedName] = useState(task.name);
  const [editedDescription, setEditedDescription] = useState(task.description);
  const [editedDueDate, setEditedDueDate] = useState(task.dueDate);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setEditedStatus(task.status);
    setEditedCategory(task.category);
    setEditedName(task.name);
    setEditedDescription(task.description);
    setEditedDueDate(task.dueDate);
  }, [task]);

  useEffect(() => {
    const fetchAssignedUserName = async () => {
      const database = getDatabase();
      const userRef = ref(database, `users/${task.assignedTo}`);
      try {
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setAssignedUserName(snapshot.val().name);
        } else {
          setAssignedUserName('Unknown User');
        }
      } catch (error) {
        console.error('Error fetching assigned user:', error);
        setAssignedUserName('Error Fetching User');
      }
    };
    if (task.assignedTo) {
      fetchAssignedUserName();
    }
  }, [task.assignedTo]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const saveChanges = () => {
    const database = getDatabase();
    set(ref(database, `tasks/${task.id}/name`), editedName);
    set(ref(database, `tasks/${task.id}/description`), editedDescription);
    set(ref(database, `tasks/${task.id}/dueDate`), editedDueDate);
    onStatusChange(task.id, editedStatus);
    onCategoryChange(task.id, editedCategory);
    setEditing(false);
  };

  const dueDate = new Date(task.dueDate);
  const currentDate = new Date();
  const timeDifference = dueDate - currentDate;
  let headerBackgroundColor = 'rgba(255, 0, 0, 0.25)';

  if (timeDifference < 0) {
    headerBackgroundColor = 'rgba(255, 0, 0, 0.25)';
  } else if (timeDifference < 24 * 60 * 60 * 1000) {
    headerBackgroundColor = 'rgba(255, 165, 0, 0.25)';
  } else {
    headerBackgroundColor = 'rgba(0, 255, 0, 0.25)';
  }

  const statusOptions = [
    { key: 'open', text: 'Open', value: 'Open' },
    { key: 'in-progress', text: 'In-progress', value: 'In-progress' },
    { key: 'completed', text: 'Completed', value: 'Completed' },
  ];

  const renderFileLink = () => {
    if (task.fileUrls && task.fileUrls.length > 0) {
      return task.fileUrls.map((url, index) => {
        const decodedUrl = decodeURIComponent(url);
        const fileName = decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1).split('?')[0];
        return (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="attach" />
            <a href={url} target="_blank" rel="noopener noreferrer">{fileName}</a>
          </div>
        );
      });
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="attach" />
        <span>No files</span>
      </div>
    );
  };

  const headerStyle = {
    backgroundColor: headerBackgroundColor,
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    height: '40px',
    fontWeight: 'bold',
    color: 'black',
    position: 'relative',
  };

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '225px',
    height: 'auto', // Adjust height to fit content
    margin: '5px',
    overflow: 'hidden',
    border: selected ? '2px solid blue' : '1px solid #d4d4d5',
    position: 'relative',
  };

  const editIconStyle = {
    cursor: 'pointer',
    visibility: isHovered ? 'visible' : 'hidden',
  };

  const descriptionStyle = {
    maxHeight: '70px',
    color: 'black',
    overflow: 'auto',
    overflowY: 'auto',
    textOverflow: 'ellipsis',
  };

  const cardContentExtraStyle = {
    padding: '10px',
  };

  const editingSectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '10px',
  };

  const inputStyle = {
    width: '100%',
    marginBottom: '5px',
  };

  const dropdownStyle = {
    width: '100%',
    marginBottom: '5px',
  };

  const buttonGroupStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px',
    flexShrink: 0,
    marginTop: '10px',
  };

  const taskInfoStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5em',
    overflow: 'hidden',
    padding: '0.5em',
  };

  return (
    <Card style={cardStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div style={headerStyle}>
        <span>{task.name.length > 30 ? task.name.substring(0, 30) + '...' : task.name}</span>
        <Icon name='edit' style={editIconStyle} onClick={toggleEdit} />
      </div>
      <Card.Content style={{ flex: '1 0 auto' }}>
        <div style={descriptionStyle}>
          {task.description}
        </div>
      </Card.Content>
      {editing ? (
        <Card.Content extra style={cardContentExtraStyle}>
          <div style={editingSectionStyle}>
            <Input
              placeholder="Task Name"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              style={inputStyle}
            />
            <TextArea
              placeholder="Description"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              style={inputStyle}
            />
            <Input
              type="date"
              value={editedDueDate}
              onChange={(e) => setEditedDueDate(e.target.value)}
              style={inputStyle}
            />
            <Dropdown
              placeholder="Status"
              selection
              options={statusOptions}
              value={editedStatus}
              onChange={(e, { value }) => setEditedStatus(value)}
              style={dropdownStyle}
            />
            <Dropdown
              placeholder="Category"
              selection
              options={categoryOptions}
              value={editedCategory}
              onChange={(e, { value }) => setEditedCategory(value)}
              style={dropdownStyle}
            />
            <Button.Group style={buttonGroupStyle}>
              <Button primary onClick={saveChanges}>Save</Button>
              <Button.Or />
              <Button onClick={toggleEdit}>Cancel</Button>
            </Button.Group>
          </div>
        </Card.Content>
      ) : (
        <Card.Content extra style={{ flex: '0 1 auto' }}>
          <div style={taskInfoStyle}>
            {renderFileLink()}
            <div><Icon name="calendar alternate outline" />{task.dueDate}</div>
            <div><Icon name="user" />Assigned to: {assignedUserName}</div>
            <div><Icon name="info circle" />{task.status}</div>
          </div>
        </Card.Content>
      )}
    </Card>
  );
};

export default TaskCard;
