import React, { useState, useEffect, useRef } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import { defaults as defaultControls } from 'ol/control';
import { Vector as VectorLayer, Tile as TileLayer, Group as LayerGroup } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Style, Circle as CircleStyle, Fill, Stroke } from 'ol/style';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import LayerSwitcher from 'ol-layerswitcher';
import { getDatabase, ref, onValue } from 'firebase/database';
import '../App.css';

const LandUseColours = {
  'Comparison': '#d32f2f', // Red
  'Convenience': '#e57373', // Light Red
  'Financial and Business Service': '#f0f4c3', // Light Green/Yellow
  'Health and Medical Services': '#9c27b0', // Purple
  'Leisure Services': '#8d6e63', // Brown
  'Public Service': '#7e57c2', // Dark Purple
  'Religious Service': '#ffeb3b', // Yellow
  'Residential': '#ffff00', // Bright Yellow
  'Retail Service': '#d32f2f', // Red
  'Storage and Warehousing': '#f0f4c3', // Light Green/Yellow
  'Vacant Residential': '#fdd835', // Gold
  'Vacant Non-Residential': '#000000', // Black
  'Car Park': '#ADD8E6', // Light Blue (for example)
};

const MapComponent = ({ isActive }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const markerLayerRef = useRef(new VectorLayer({
    title: 'Land Use Entries',
    source: new VectorSource(),
    visible: true,
  }));
  const initialViewParams = useRef({
    center: fromLonLat([-7.150201194732156, 53.01571951784325]),
    zoom: 14,
  });
  const [landUseEntries, setLandUseEntries] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    if (!map) return;
  
    const popupElement = document.createElement('div');
    popupElement.className = 'popup-content'; // Assign the CSS class
    popupRef.current = new Overlay({
      element: popupElement,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
      offset: [0, -20] // Offset the popup to be above the marker
    });
    map.addOverlay(popupRef.current);
  }, [map]);

  useEffect(() => {
    if (!isActive || !mapRef.current) return;

    const landUseRef = ref(getDatabase(), 'landUseRegistry');
    const unsubscribe = onValue(landUseRef, (snapshot) => {
      const entries = snapshot.val();
      if (entries) {
        const markers = Object.values(entries).map(entry => {
          if (entry.geolocation && typeof entry.geolocation.lat === 'number' && typeof entry.geolocation.lng === 'number') {
            return {
              position: [entry.geolocation.lng, entry.geolocation.lat],
              info: entry,
            };
          } else {
            console.error('Invalid geolocation data', entry);
            return null;
          }
        }).filter(Boolean);
        setLandUseEntries(markers);
      }
    });

    return () => unsubscribe();
  }, [isActive]);

  useEffect(() => {
    if (!map || !landUseEntries.length) return;

    const features = landUseEntries.map(entry => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([entry.position[0], entry.position[1]])),
        info: entry.info,
      });
      feature.setStyle(new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({ color: LandUseColours[entry.info.type] }),
          stroke: new Stroke({ color: '#fff', width: 1 }),
        }),
      }));
      return feature;
    });

    const vectorSource = new VectorSource({
      features: features,
    });

    markerLayerRef.current.setSource(vectorSource);
  }, [landUseEntries, map]);

  useEffect(() => {
    if (!map) return;
  
    const displayPopup = (evt) => {
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature);
    
      if (feature) {
        const coordinates = feature.getGeometry().getCoordinates();
        const info = feature.get('info');
    
        if (info) {
          const content = `
            <div style="
              background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
              border: 2px solid #333; /* Solid border */
              border-radius: 10px;
              padding: 10px;
              box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
              color: #333; /* Dark text color */
            ">
              <p>Floor: ${info.floor}</p>
              <p>House Number: ${info.houseNumber}</p>
              <p>Occupancy: ${info.occupancy}</p>
              <p>Postal Code: ${info.postalCode}</p>
              <p>Street Name: ${info.streetName}</p>
              <p>Type: ${info.type}</p>
            </div>
          `;
    
          popupRef.current.getElement().innerHTML = content;
          const pixel = map.getPixelFromCoordinate(coordinates);
          pixel[0] += 8; // Offset by 8 pixels to the right
          popupRef.current.setPosition(map.getCoordinateFromPixel(pixel));
        }
      } else {
        popupRef.current.setPosition(undefined);
      }
    };
    
  
    map.on('pointermove', displayPopup);
  
    return () => map.un('pointermove', displayPopup);
  }, [map]);
  

  useEffect(() => {
    if (!mapRef.current) return;

    const baseLayer = new TileLayer({
      title: 'OpenStreetMap',
      type: 'base',
      visible: true,
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    });

    const satelliteLayer = new TileLayer({
      title: 'Satellite',
      type: 'base',
      visible: false,
      source: new XYZ({
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      }),
    });

    const customLayer = new TileLayer({
      title: 'Laois PRIME 2 Map',
      visible: true,
      source: new XYZ({
        url: 'https://locusterra-d3d24.web.app/tiles/{z}/{x}/{-y}.png',
      }),
    });

    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new LayerGroup({
          title: 'Base maps',
          layers: [baseLayer, satelliteLayer],
        }),
        new LayerGroup({
          title: 'Overlays',
          layers: [customLayer, markerLayerRef.current],
        }),
      ],
      view: new View({
        center: initialViewParams.current.center,
        zoom: initialViewParams.current.zoom,
        maxZoom: 19,
      }),
      controls: defaultControls().extend([new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })]),
    });

    setMap(initialMap);

    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  return (
    <div>
      <div ref={mapRef} style={{ height: '600px', width: '100%' }}></div>
    </div>
  );
};

export default MapComponent;