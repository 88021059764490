import React, { useState } from 'react';
import { Form, Button, Dropdown, Message } from 'semantic-ui-react';
import { getDatabase, ref, push, set } from 'firebase/database';
import axios from 'axios';
import { firebaseConfig } from './Firebase'; // Correctly import firebaseConfig
import { initializeApp } from 'firebase/app';

// Initialize Firebase app
initializeApp(firebaseConfig);

// Define the floor options
const floorOptions = [
  { key: '0', text: 'Ground Level (0)', value: '0' },
  { key: '1', text: 'First Floor (+1)', value: '1' },
  { key: '2', text: 'Second Floor (+2)', value: '2' },
  { key: '3', text: 'Third Floor (+3)', value: '3' },
  { key: '4', text: 'Fourth Floor (+4)', value: '4' },
  { key: 'roof', text: 'Roof', value: 'roof' },
  { key: '-1', text: 'Basement (-1)', value: '-1' },
  { key: '-2', text: 'Basement (-2)', value: '-2' },
];

// Updated typeOptions with new categories
const typeOptions = [
  { key: 'comparison', text: 'Comparison', value: 'Comparison' },
  { key: 'convenience', text: 'Convenience', value: 'Convenience' },
  { key: 'financial_business_service', text: 'Financial and Business Service', value: 'Financial and Business Service' },
  { key: 'health_medical_services', text: 'Health and Medical Services', value: 'Health and Medical Services' },
  { key: 'leisure_services', text: 'Leisure Services', value: 'Leisure Services' },
  { key: 'public_service', text: 'Public Service', value: 'Public Service' },
  { key: 'religious_service', text: 'Religious Service', value: 'Religious Service' },
  { key: 'residential', text: 'Residential', value: 'Residential' },
  { key: 'retail_service', text: 'Retail Service', value: 'Retail Service' },
  { key: 'storage_warehousing', text: 'Storage and Warehousing', value: 'Storage and Warehousing' },
  { key: 'vacant_residential', text: 'Vacant Residential', value: 'Vacant Residential' },
  { key: 'vacant_non_residential', text: 'Vacant Non-Residential', value: 'Vacant Non-Residential' },
  { key: 'car_park', text: 'Car Park', value: 'Car Park' },
];

const occupancyOptions = [
  { key: 'in_use', text: 'In-use', value: 'In-use' },
  { key: 'vacant', text: 'Vacant', value: 'Vacant' },
  { key: 'derelict', text: 'Derelict', value: 'Derelict' },
];

// Add a new function to check if the form is valid
const isFormValid = (formState) => {
  return formState.streetName && formState.houseNumber && formState.type && formState.floor && formState.occupancy;
};

const LandUse = () => {
  const [formState, setFormState] = useState({
    streetName: '',
    houseNumber: '',
    type: '',
    floor: '',
    occupancy: '',
    geolocation: { lat: null, lng: null }, // Add default value for geolocation
    postalCode: '',
  });

  const [message, setMessage] = useState(''); // State for success message
  const [isSuccess, setIsSuccess] = useState(false); // State for message type

  const database = getDatabase();

  const handleInputChange = (e, { name, value }) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async () => {
    if (!isFormValid(formState)) {
      return; // Do not submit if form is invalid
    }

    try {
      const address = `${formState.houseNumber} ${formState.streetName}`;
      console.log('Requesting geocode for address:', address); // Log the address

      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address: address,
          key: 'AIzaSyBgMRc2kHSqryUDKeFZQRNGr91MAbgmnuw',
        },
      });

      console.log('Geocode API response:', response.data); // Log the response data

      if (response.data.status === 'ZERO_RESULTS') {
        throw new Error('No results found for the provided address.');
      } else if (response.data.status !== 'OK' || response.data.results.length === 0) {
        throw new Error('Invalid address or no results found.');
      }

      const geolocation = response.data.results[0].geometry.location;
      const postalCode = response.data.results[0].address_components.find(c => c.types.includes('postal_code'))?.long_name || '';

      // Now we have all the data, push it to Firebase
      const newEntryRef = push(ref(database, 'landUseRegistry'));
      await set(newEntryRef, {
        ...formState,
        geolocation,
        postalCode,
      });

      console.log('Data successfully written to Firebase:', { ...formState, geolocation, postalCode });

      // Set success message
      setMessage('Entry has been added to the database. The map is now updated.');
      setIsSuccess(true);

      // Reset form state after submission
      setFormState({
        streetName: '',
        houseNumber: '',
        type: '',
        floor: '',
        occupancy: '',
        geolocation: { lat: null, lng: null },
        postalCode: '',
      });

    } catch (error) {
      console.error('Error fetching geocoding data or writing to Firebase:', error.message); // Log the error message
      setMessage(`Error: ${error.message}`);
      setIsSuccess(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {message && (
        <Message positive={isSuccess} negative={!isSuccess}>
          {message}
        </Message>
      )}
      <Form.Input
        label='Street Name'
        name='streetName'
        value={formState.streetName}
        onChange={handleInputChange}
      />
      <Form.Input
        label='House Number'
        name='houseNumber'
        value={formState.houseNumber}
        onChange={handleInputChange}
      />
      <Form.Field>
        <label>Type</label>
        <Dropdown
          placeholder='Select Type'
          fluid
          selection
          options={typeOptions}
          name='type'
          value={formState.type}
          onChange={handleInputChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Floor</label>
        <Dropdown
          placeholder='Select Floor'
          fluid
          selection
          options={floorOptions}
          name='floor'
          value={formState.floor}
          onChange={handleInputChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Occupancy</label>
        <Dropdown
          placeholder='Select Occupancy'
          fluid
          selection
          options={occupancyOptions}
          name='occupancy'
          value={formState.occupancy}
          onChange={handleInputChange}
        />
      </Form.Field>
      <Button type='submit' disabled={!isFormValid(formState)}>Submit</Button>
    </Form>
  );
};

export default LandUse;
