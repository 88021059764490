// TaskOptions.js
export const priorityOptions = [
    { key: 'high', text: 'High', value: 'High' },
    { key: 'medium', text: 'Medium', value: 'Medium' },
    { key: 'low', text: 'Low', value: 'Low' },
  ];
  
  export const categoryOptions = [
    { key: 'work', text: 'Work', value: 'Work' },
    { key: 'personal', text: 'Personal', value: 'Personal' },
    // Add more categories as needed
  ];
  
  export const statusOptions = [
    { key: 'o', text: 'Open', value: 'Open' },
    { key: 'ip', text: 'In-progress', value: 'In-progress' },
    { key: 'c', text: 'Completed', value: 'Completed' },
  ];