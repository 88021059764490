import React from 'react';
import { Menu, Button, Icon, Modal, Header } from 'semantic-ui-react';
import SignIn from './Authentication/SignIn'
import SignUp from './Authentication/SignUp';
import ForgotPassword from './Authentication/ForgotPassword'; // Make sure to import ForgotPassword component

const WelcomeMessage = ({ userName, onSignOff, isAuthenticated }) => {
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openForgotPassword, setOpenForgotPassword] = React.useState(false); // New state for forgot password modal

  return (
    <Menu secondary>
      <Menu.Menu position='right'>
        {isAuthenticated ? (
          <>
            <Menu.Item>
              <Icon name='user circle' size='large' />
              Welcome, {userName}!
            </Menu.Item>
            <Menu.Item>
              <Button onClick={onSignOff} color='red'>
                <Icon name='sign out' /> Sign Off
              </Button>
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item>
              <Button onClick={() => setOpenSignIn(true)}>Sign In</Button>
            </Menu.Item>
            <Menu.Item>
              <Button onClick={() => setOpenSignUp(true)}>Sign Up</Button>
            </Menu.Item>

            {/* SignIn Modal */}
            <Modal
              basic
              onClose={() => setOpenSignIn(false)}
              onOpen={() => setOpenSignIn(true)}
              open={openSignIn}
              size='small'
            >
              <Header icon>
                <Icon name='user circle' />
                Sign In
              </Header>
              <Modal.Content>
{/*                 <SignIn onSignIn={() => setOpenSignIn(false)} onForgotPassword={() => setOpenForgotPassword(true)} />
 */}              
                <SignIn
                  onSignIn={() => setOpenSignIn(false)}
                  onForgotPassword={() => {
                    setOpenSignIn(false); // Close the Sign In modal
                    setOpenForgotPassword(true); // Open the Forgot Password modal
                  }}
                />
              </Modal.Content>
            </Modal>
            {/* ForgotPassword Modal */}
            <Modal
              basic
              onClose={() => setOpenForgotPassword(false)}
              open={openForgotPassword}
              size='small'
            >
              <Header icon>
                <Icon name='lock' />
                Reset Password
              </Header>
              <Modal.Content>
                <ForgotPassword onClose={() => setOpenForgotPassword(false)} />
              </Modal.Content>
            </Modal>
            {/* SignUp Modal */}
            <Modal
              basic
              onClose={() => setOpenSignUp(false)}
              onOpen={() => setOpenSignUp(true)}
              open={openSignUp}
              size='small'
            >
              <Header icon>
                <Icon name='user plus' />
                Sign Up
              </Header>
              <Modal.Content>
                <SignUp onSignUp={() => setOpenSignUp(false)} />
              </Modal.Content>
            </Modal>
          </>
        )}
      </Menu.Menu>
    </Menu>
  );
};

export default WelcomeMessage;
