import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut, 
  sendPasswordResetEmail as firebaseSendPasswordResetEmail
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAkAJ2yBDW8tuPclUQ5piqmSdE5dCLnZv0",
  authDomain: "locusterra-d3d24.firebaseapp.com",
  databaseURL: "https://locusterra-d3d24-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "locusterra-d3d24",
  storageBucket: "locusterra-d3d24.appspot.com",
  messagingSenderId: "778328126258",
  appId: "1:778328126258:web:0a56846d73cd861c3bdd2b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Export firebaseConfig
export { firebaseConfig };

// Export auth related functions
export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signOut = () => {
  return firebaseSignOut(auth);
};

export const sendPasswordResetEmail = (email) => {
  return firebaseSendPasswordResetEmail(auth, email);
};

export const signInWithGoogle = () => {
  return signInWithPopup(auth, googleProvider);
};

export { auth, createUserWithEmailAndPassword };
