import React, { useState } from 'react';
import { Button, Form, Input, Message, Segment } from 'semantic-ui-react';
import { getDatabase, ref, set } from 'firebase/database';
import { auth, createUserWithEmailAndPassword } from '../Firebase'; // Adjust the import path as needed

const SignUp = ({ onSignUpSuccess, onSignUpError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState(''); // State for the name
  const [error, setError] = useState(null);

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError(null); // Clear any previous errors
  
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
  
      // Get a reference to the Realtime Database
      const database = getDatabase();
  
      // Write the user's information to the database
      await set(ref(database, `users/${uid}`), {
        name: name,
        email: email,
        // Add any other user info you'd like to store, such as 'name' if you have a name field
      });
  
      onSignUpSuccess(); // Notify the parent component of the success
    } catch (error) {
      setError(error.message); // Set error message to display to the user
      if (onSignUpError) onSignUpError(error); // Notify the parent component of the error
    }
  };

  return (
    <Segment padded>
      {error && <Message error header="Sign Up Failed" content={error} />}
      <Form onSubmit={handleSignUp}>
        <Form.Field>
          <Input 
            icon='user' 
            iconPosition='left' 
            placeholder='Name' // Placeholder for name
            type='text' 
            value={name} 
            onChange={(e) => setName(e.target.value)} // Handler for name
          />
        </Form.Field>
        <Form.Field>
          <Input 
            icon='mail' 
            iconPosition='left' 
            placeholder='Email' 
            type='email' 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </Form.Field>
        <Form.Field>
          <Input 
            icon='lock' 
            iconPosition='left' 
            placeholder='Password' 
            type='password' 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </Form.Field>
        <Button primary fluid type='submit'>Sign Up</Button>
      </Form>
    </Segment>
  );
};

export default SignUp;
