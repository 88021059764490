import React, { useState } from 'react';
import { Button, Form, /* Icon, */ Input, Message } from 'semantic-ui-react';
import { signIn/* , signInWithGoogle */ } from '../Firebase'; // Adjust the import path as needed

// Added onForgotPassword to the component's props
const SignIn = ({ onSignInSuccess, onSignInError, onForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null); // Clear any previous errors
    try {
      await signIn(email, password);
      onSignInSuccess(); // Notify the parent component of the success
    } catch (error) {
      setError(error.message); // Set error message to display to the user
      if (onSignInError) onSignInError(error); // Optionally notify the parent component of the error
    }
  };

/*   const handleGoogleSignIn = async () => {
    setError(null); // Clear any previous errors
    try {
      await signInWithGoogle();
      onSignInSuccess(); // Notify the parent component of the success
    } catch (error) {
      setError(error.message); // Set error message to display to the user
      if (onSignInError) onSignInError(error); // Notify the parent component of the error
    }
  }; */
  
return (
    <div>
      {error && <Message error header="Sign In Failed" content={error} />}
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Input
            icon='user'
            iconPosition='left'
            placeholder='Email'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <Input
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Field>
        <Button fluid primary type='submit'>Sign In</Button>
        {/*       <Button fluid color='google plus' onClick={handleGoogleSignIn} style={{ marginTop: '1em' }}>
          <Icon name='google' /> Sign in with Google
        </Button> */}
        {/* Added "Forgot Password?" link below the sign-in button */}
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <button
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor behavior
              e.stopPropagation(); // Optional: stop event from bubbling up
              onForgotPassword(); // Trigger the onForgotPassword function
            }}
            style={{
              background: 'none',
              color: 'blue',
              border: 'none',
              padding: '0',
              font: 'inherit',
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
          >
            Forgot Password?
          </button>
        </div>
      </Form>
    </div>
  );
};


export default SignIn;
