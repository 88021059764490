import React from 'react';
import { Segment, Image } from 'semantic-ui-react';
import CompanyLogo from '../images/Locus.jpg'; // Adjust the path as needed

const Home = () => (
  <Segment className="component-container" style={styles.segment}>
    <div style={styles.content}>
      <Image src={CompanyLogo} alt="Company Logo" style={styles.logo} />
      <div style={styles.textContainer}>
        <h1>Welcome to Locus Terra - Land Mapped </h1>
        <p style={styles.paragraph}>
          Stradbally: A Collaborative Audit of its Town Centre Street[s] and Buildings.
        </p>
        <p style={styles.paragraph}>
          Upon Signing In, you will find a variety of tools to support your analysis. 
        </p>
        <p style={styles.paragraph}>
        Get started by exploring the features listed on the navigation bar.
        </p>
        <p style={styles.footerText}>
          Locus Terra - Land Mapped Ⓒ 2023/24

        </p>
        <p style={styles.footerText}>
          Contact: technical@locusterra.com
        </p>
      </div>
    </div>
  </Segment>
);

const styles = {
  segment: {
    textAlign: 'center',
    padding: '30px',
    backgroundColor: '#f0f0f0', // Adjust the background color as needed
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: '150px', // Adjust the logo size as needed
    marginBottom: '20px',
  },
  textContainer: {
    maxWidth: '600px',
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.5',
    marginBottom: '20px', // Add margin between paragraphs
  },
  footerText: {
    marginTop: '20px',
    fontSize: '14px',
    color: '#777', // Adjust the text color as needed
  },
};

export default Home;

// keep project files, view maps, manage tasks and more. 