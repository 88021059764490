import React, { useState, useEffect, Suspense } from 'react';
import { Menu } from 'semantic-ui-react';
import WelcomeMessage from './components/WelcomeMessage';
import Home from './components/Home'; // Assume Home is a component you want to load lazily
import FileManagement from './components/FileManagement';
import MapComponent from './components/MapComponent';
//import RecentActivity from './components/RecentActivity';
import Tasks from './components/Tasks'; // Import the Tasks component
import { auth, signOut } from './components/Firebase';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import WithGoogleScript from './components/WithGoogleScript';
import LandUse from './components/LandUse';

const App = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleSignOff = async () => {
    try {
      await signOut();
      setActiveTab('home'); // Redirect to home after sign out
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <div className="app-container">
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <WelcomeMessage 
        userName={currentUser ? currentUser.email : 'Guest'}
        onSignOff={handleSignOff}
        isAuthenticated={!!currentUser}
      />
      {currentUser && (
        <Menu tabular>
          <Menu.Item
            name='home'
            active={activeTab === 'home'}
            onClick={() => setActiveTab('home')}
          />
          <Menu.Item
            name='Map'
            active={activeTab === 'Map'}
            onClick={() => setActiveTab('Map')}
          />
{/*           <Menu.Item
            name='recent activity'
            active={activeTab === 'recentActivity'}
            onClick={() => setActiveTab('recentActivity')}
          /> */}
          <Menu.Item
            name='Land Use'
            active={activeTab === 'Land Use'}
            onClick={() => setActiveTab('Land Use')}
          />
          <Menu.Item
            name='Files'
            active={activeTab === 'Files'}
            onClick={() => setActiveTab('Files')}
          />
          <Menu.Item
            name='tasks' // Add a menu item for Tasks
            active={activeTab === 'tasks'}
            onClick={() => setActiveTab('tasks')}
          />
        </Menu>
      )}
      <div className="app-content">
        {activeTab === 'home' && <Home />}
        {activeTab === 'Files' && <FileManagement />}
        {activeTab === 'Map' && <MapComponent isActive={activeTab === 'Map'} />}
        {/*activeTab === 'recentActivity' && <RecentActivity />*/} 
        {activeTab === 'tasks' && <Tasks />} {/* Render the Tasks component */}
        {activeTab === 'Land Use' && <LandUse />} {/* Render the Land use component */}
      </div>
    </div>
  );
};

export default WithGoogleScript(App);
